<template>
  <div>
    <div v-if="hasPower!=null&&hasPower" v-loading="loading">
      <van-cell-group title="注册机">
        <van-field label="产品名称" is-link readonly v-model="selectProduct.productName" @click="showPicker=true" input-align="right"></van-field>
        <van-field label="数量" v-model.number="count" input-align="right" type="digit"></van-field>
      </van-cell-group>
      <el-row type="flex" justify="space-around" style="margin-top:10px">
        <el-col :span="10">
          <van-button type="info" block @click="handleClickRegister(false)">生成</van-button>
        </el-col>
        <el-col :span="10">
          <van-button type="primary" block @click="handleClickRegister(true)">生成并打印</van-button>
        </el-col>
      </el-row>

      <van-popup v-model="showPicker" position="bottom">
        <van-picker :columns="columns" show-toolbar @cancel="showPicker=false" @confirm="handleConfirm" value-key="productName"></van-picker>
      </van-popup>
      <el-alert v-if="responseCode==101" :title="responseData" center type="success" :closable="false" style="margin-top:10px">
      </el-alert>
      <el-alert v-else-if="responseCode==201" :title="responseData" center type="error" :closable="false" style="margin-top:10px">
      </el-alert>
    </div>
    <el-alert v-else-if="hasPower!=null&&!hasPower" class="sumjcenter1" title="无权限" center type="warning" :closable="false">
    </el-alert>
  </div>
</template>

<script>
import Vue from "vue";
import Vant from "vant";
import "vant/lib/index.css";

Vue.use(Vant);

export default {
  data() {
    return {
      selectProduct: {},
      showPicker: false,
      columns: [],
      loading: false,
      count: 1,
      responseCode: "",
      responseData: "",
      hasPower: null,
    };
  },
  methods: {
    handleConfirm(obj) {
      let that = this;
      that.selectProduct = obj;
      that.showPicker = false;
    },
    handleCompanyNameConfirm(obj) {
      let that = this;
      that.companyName = obj.dataText;
      that.showCompanyNamePicker = false;
    },
    handleClickRegister(ifPrint) {
      let that = this;
      that.loading = true;
      that.responseCode = "";
      that.axios
        .post("Base_Device/Register1", {
          productName: that.selectProduct.productName,
          productId: that.selectProduct.id,
          count: that.count,
          deviceType: that.selectProduct.productType,
          ifPrint: ifPrint,
        })
        .then(function (response) {
          that.loading = false;
          that.responseCode = response.data.code;
          that.responseData = response.data.data;
        });
    },
  },
  mounted() {
    let that = this;
    document.title = "注册机";
    //获取权限
    that.axios
      .post("Base/HasPower", { powerKey: "SerialNumberRegister" })
      .then(function (response) {
        that.hasPower = response.data.data;
      });
    that.axios.get("Product/GetHasNotNumber").then(function (response) {
      that.columns = response.data.data;
    });
  },
};
</script>

<style scoped>
.sumjcenter1 {
  margin-top: 50%;
}
</style>
